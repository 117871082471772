<template>
<!-- 活动页面 -->
  <div>
    <div class="zt-banner">
      <div class="container">
        <div class="zt-grid">
          <div class="zt-lg flex flex-ac flex-hc">
            <img src="../../assets/activity_images/pc/tt1.png" />
            <img src="../../assets/activity_images/pc/tt2.png" />
          </div>
          <div class="zt-sm flex flex-ac flex-hc">
            <img src="../../assets/activity_images/pc/sm-bt.png" />
            <img src="../../assets/activity_images/pc/day6.png" />
          </div>
          <div class="zt-text">
            注意：以上课程仅指 CSON官方出品的 「{{ this.giveCourseName }}」
          </div>
          <div class="zt-link flex flex-ac flex-hc" style="display: block">
            <a href="javascript:;" class="btn" @click="getInvitationLink"
              >立即参与</a
            >
            <a
              href="javascript:;"
              class="zt-text"
              style="display: block; width: 100px; margin: 0 auto"
              @click="getInvitationList"
              >邀请记录</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="zt-wrapper">
      <div class="container">
        <div class="zt-panel">
          <div class="abs-text"></div>
          <div class="inner">
            <div class="zt-cell">
              <div class="zt-head flex flex-ac">
                <div class="zt-tit">邀请规则</div>
                <img src="../../assets/activity_images/pc/invite.png" />
              </div>
              <div class="zt-tip">
                活动期间，每邀请一位好友使用您的邀请码注册成功、成功购课，您和TA都将获得课程免费学习时长：
              </div>
              <ul class="zt-list">
                <li class="flex flex-ac" v-if="buyCombo">
                  <img
                    src="../../assets/activity_images/pc/flay.png"
                  />邀请好友注册成功，获得「{{ this.giveCourseName }}」<span
                    class="lg-red"
                    >{{ buyCombo.complimentaryDay }}天</span
                  >
                  延期；
                </li>
                <li class="flex flex-ac" v-if="invitationRegister">
                  <img
                    src="../../assets/activity_images/pc/flay.png"
                  />邀请好友购课成功，获得「{{ this.giveCourseName }}」<span
                    class="lg-red"
                    >{{ invitationRegister.complimentaryDay }}天</span
                  >
                  延期；
                </li>
                <li class="flex flex-ac">
                  <img src="../../assets/activity_images/pc/flay.png" />邀请者
                  <span class="lg-red">最多</span> 获得
                  <span class="lg-red">{{ tallest }}天</span> 奖励。
                </li>
              </ul>
              <!--              <div class="zt-end">*被邀请者成功注册后，邀请者需主动联系客服，发送被邀请者绑定用户名或邮箱，审核通过即发放奖励。</div>-->
            </div>
            <div class="zt-cell">
              <div class="zt-head flex flex-ac">
                <div class="zt-tit">
                  奖励发放
                  <img src="../../assets/activity_images/pc/sm-gift.png" />
                </div>
                <img src="../../assets/activity_images/pc/reward.png" />
              </div>
              <div class="zt-tip">
                奖励会叠加在您的课程中，您可以通过“我的课程”查看您的课程奖励
              </div>
            </div>
            <div class="zt-cell">
              <div class="zt-head flex flex-ac">
                <div class="zt-tit">特别说明</div>
                <img src="../../assets/activity_images/pc/Explanation.png" />
              </div>
              <ul class="zt-list">
                <li class="flex flex-ac">
                  <img
                    src="../../assets/activity_images/pc/sanjiao.png"
                  />以上奖励规则仅适用于
                  <span class="bg-sm"
                    >{{ this.active.startTime }}-{{
                      this.active.terminationTime
                    }}期间</span
                  >
                  邀请注册并下单购课的好友;
                </li>
                <li class="flex flex-ac">
                  <img src="../../assets/activity_images/pc/sanjiao.png" /><span
                    class="bg-sm"
                    >同一邮箱、同一微信ID</span
                  >
                  都将被视为同一个用户，不再重复获得奖励。
                </li>
              </ul>
              <div class="zt-end">
                *如果您没有收到奖励，请在微信中与我们联系核实。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- div.gift -->
    <div class="gift-fixed" @click="triggerVisible">
      <div class="box-popover" v-if="visible">
        <div class="inner">
          <img src="../../assets/activity_images/pc/wx.png" />
          <div class="txt1">扫描微信二维码<br />课程和网站疑问</div>
          <div class="txt2">针对优惠有疑问?<br />购买时遇到问题?</div>
        </div>
      </div>
    </div>
    <el-dialog custom-class="custom-dialog" :visible.sync="dialogFormVisible"  :close-on-click-modal="false">
      <div class="dialog-main">
        <div class="d-flex flex flex-ac">
          <div class="d-title">{{ this.active.activeName }}</div>
          <div class="d-text">
            最高可享 <span class="lg">{{ this.tallest }}天</span> 免费学
          </div>
        </div>
        <div class="d-flex-code flex flex-ac">
          <div class="txt">专属邀请链接</div>
          <input v-model="userInvitationLink" readonly="readonly" />
          <div class="btn" @click="copyUrl">复制</div>
        </div>
        <div class="d-wx">
          <img src="../../assets/activity_images/m/wx.png" />
        </div>
        <div class="d-sm">扫码获取活动详细流程</div>
      </div>
    </el-dialog>
    <el-dialog custom-class="custom-dialog" :visible.sync="dialogFormVisible2" :close-on-click-modal="false">
      <div class="dialog-main">
        <div class="d-title" style="margin-bottom: 40px">新用户福利</div>
        <ul class="zt-list">
          <li class="flex flex-ac">
            <img src="../../assets/activity_images/m/sanjiao.png" />{{
              this.giveCourseName
            }}
            <span class="bgx-sm"
              >免费学<span class="lg">{{ register.complimentaryDay }}</span
              >天</span
            >
          </li>
          <li class="flex flex-ac">
            <img
              src="../../assets/activity_images/m/sanjiao.png"
            />活动时间：<span class="bg-sm"
              >{{ this.active.startTime }}-{{
                this.active.terminationTime
              }}</span
            >
          </li>
        </ul>
        <a href="#/registerform" class="d-button">立即注册</a>
      </div>
    </el-dialog>
    <el-dialog custom-class="custom-dialog" :visible.sync="dialogFormVisible3"  :close-on-click-modal="false">
      <div class="dialog-main">
        <div class="d-title" style="margin-bottom: 40px">邀请记录</div>
        <div class="table-in">
          <table class="el-table">
            <thead>
              <tr>
                <td colspan="2">用户名</td>
                <td>状态</td>
                <td>奖励天数</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in userInvitationList" :key="index">
                <td style="padding: 0 0; margin: 0 0">
                  <img :src="item.avatar" />
                </td>
                <td style="text-align: left">{{ item.username }}</td>
                <td>
                  {{
                    item.activeChannelType.value == 0
                      ? "新用户注册"
                      : item.activeChannelType.value == 1
                      ? "激活成功"
                      : "购课成功"
                  }}
                </td>
                <td>奖励{{ item.complimentaryDay }}天</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  invitationList,
  invitationLink,
  getAcitve,
} from "../../service/index.js";

export default {
  name: "activity",
  components: {},
  computed: mapState(["userInfo"]),
  data() {
    return {
      visible: false,
      visible2: true,
      dialogFormVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      userInvitationList: [],
      userInvitationLink: "",
      active: {},
      tallest: 0,
      buyCombo: {},
      invitationRegister: {},
      register: {},
      giveCourseName: "",
    };
  },
  created() {
    // this.setEndTime(10);
    // console.log(this.startTime);
    this.getInvitationActive();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    getInvitationActive() {
      //邀请活动固定写 0
      getAcitve(0).then((res) => {
        if (res.success) {
          this.active = res.result;
          res.result.activeDetails.forEach((item) => {
            this.tallest += item.complimentaryDay * item.limitNum;
            this.giveCourseName = item.giveCourseName;
            switch (item.activeChannelType.value) {
              case 0:
                this.register = item;
                break;
              case 1:
                this.invitationRegister = item;
                break;
              case 2:
                this.buyCombo = item;
                break;
              default:
                break;
            }
          });
          this.tallest += 1;
        }
      });
    },

    triggerVisible() {
      this.visible = !this.visible;
    },
    getInvitationList() {
      this.dialogFormVisible3 = true;
      if (this.userInfo.id) {
        invitationList(this.active.activeId).then((res) => {
          if (res.success) {
            this.userInvitationList = res.result;
          }
        });
      }
    },
    getInvitationLink() {
      if (this.userInfo.id) {
        this.dialogFormVisible = true;
        invitationLink().then((res) => {
          if (res.success) {
            this.userInvitationLink = res.result;
          }
        });
      } else {
        this.dialogFormVisible2 = true;
      }
    },
    copyUrl() {
      var domUrl = document.createElement("input");
      domUrl.value = this.userInvitationLink;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message.success("复制成功");
    },
  },
};
</script>

<style scoped="scoped">
/* ad */

.flex {
  display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
  display: -moz-box; /* 老版本语法: Firefox (buggy) */
  display: -ms-flexbox; /* 混合版本语法: IE 10 */
  display: -webkit-flex; /* 新版本语法: Chrome 21+ */
  display: flex; /* 新版本语法: Opera 12.1, Firefox 22+ */
}

/* 主轴居中 */
.flex-hc {
  -webkit-box-pack: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
}

/* 主轴两端对齐 */
.flex-zBetween {
  -webkit-box-pack: justify;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

/* 主轴end对齐 */
.flex-zEnd {
  -webkit-box-pack: end;
  -moz-justify-content: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

/* 主轴start对齐 */
.flex-zStart {
  -webkit-box-pack: start;
  -moz-justify-content: flex-start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

/* 侧轴居中 */
.flex-ac {
  -webkit-box-align: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  align-items: center;
}

/* 侧轴start对齐 */
.flex-cStart {
  -webkit-box-align: start;
  -moz-align-items: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

/* 侧轴底部对齐 */
.flex-cEnd {
  -webkit-box-align: end;
  -moz-align-items: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

/* 侧轴文本基线对齐 */
.flex-cBaseline {
  -webkit-box-align: baseline;
  -moz-align-items: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}

/* 侧轴上下对齐并铺满 */
.flex-cStretch {
  -webkit-box-align: stretch;
  -moz-align-items: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
}

/* 主轴从上到下 */
.flex-zTopBottom {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

/* 主轴从下到上 */
.flex-zBottomTop {
  -webkit-box-pack: end;
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -moz-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

/* 主轴从左到右 */
.flex-zLeftRight {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

/* 主轴从右到左 */
.flex-zRightLeft {
  -webkit-box-pack: end;
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -moz-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

/* 不允许子元素缩小 */
.flex-shrink {
  -webkit-box-flex: 0;
  -moz-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

/*各个行中间对齐*/
.flex-center {
  -webkit-align-content: center;
  align-content: center;
}

/*各个行中间对齐*/
.flex-start {
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

/*各个行中间对齐*/
.flex-end {
  -webkit-align-content: flex-end;
  align-content: flex-end;
}

/*各个行平均分布*/
.flex-between {
  -webkit-align-content: space-between;
  align-content: space-between;
}

/*各个行两端保留子元素与子元素之间间距大小的一半*/
.flex-container {
  -webkit-align-content: space-around;
  align-content: space-around;
}

/*父元素-横向换行 */
.flex-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

/*父元素-不允许横向换行 */
.flex-nowrap {
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

/*充满父元素*/
.flex_bd {
  -prefix-box-flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
}

/* =---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

.zt-banner {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../assets/activity_images/pc/top.png);
  height: 500px;
}

.zt-banner .container {
  height: 100%;
}

.zt-wrapper {
  position: relative;
  z-index: 1999;
  background: url(../../assets/activity_images/pc/bg.png) center top no-repeat;
  height: 950px;
}

.zt-wrapper .container {
  width: 1218px;
}

.zt-wrapper .zt-panel {
  position: relative;
  top: -60px;
  background: url(../../assets/activity_images/pc/card-bg.png) no-repeat;
  height: 820px;
}

.zt-wrapper .zt-panel .abs-text {
  position: absolute;
  left: -90px;
  width: 80px;
  height: 470px;
  top: 300px;
  background: url(../../assets/activity_images/pc/cson-txt.png) center no-repeat;
}

.zt-panel .inner {
  padding-top: 60px;
  padding-left: 120px;
  padding-right: 200px;
}

.zt-head {
  margin-bottom: 25px;
}

.zt-head .zt-tit {
  position: relative;
  width: 150px;
  height: 38px;
  line-height: 38px;
  font-size: 18px;
  text-align: center;
  margin-right: 20px;
  color: #ffffff;
  background: url(../../assets/activity_images/pc/tt.png) no-repeat;
}

.zt-head .zt-tit img {
  position: absolute;
  right: -20px;
  top: -10px;
}

.zt-tip {
  background: #ffe6e5;
  border-radius: 2px;
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
  margin-bottom: 20px;
}

.zt-list {
  margin-bottom: 20px;
}

.zt-end {
  color: #ff5247;
  line-height: 12px;
}

.lg-red {
  color: #ff5247;
  font-size: 18px;
}

.zt-cell {
  border-bottom: 1px dashed #ff5247;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.zt-cell:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.zt-list li {
  margin-bottom: 20px;
  font-size: 16px;
}

.zt-list li:last-child {
  margin-bottom: 0;
}

.zt-list li img {
  margin-right: 20px;
}

.zt-list li .bg-sm {
  display: inline-block;
  line-height: 24px;
  padding: 0 10px;
  background: #ffe6e5;
}

.zt-grid {
  text-align: center;
  padding-top: 100px;
}

.zt-lg {
  margin-bottom: 25px;
}

.zt-sm {
  margin-bottom: 25px;
}

.zt-text {
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  line-height: 27px;
  display: inline-block;
  border-bottom: 1px solid #ffffff;
  margin-bottom: 40px;
}

.zt-link .btn {
  display: block;
  width: 252px;
  height: 52px;
  line-height: 48px;
  text-align: center;
  color: #ff2b39;
  font-size: 16px;
  background: url(../../assets/activity_images/pc/button.png) no-repeat;
  margin: 0 auto;
}

.gift-fixed {
  position: fixed;
  top: 50%;
  right: 10px;
  z-index: 1999;
  width: 100px;
  height: 92px;
  cursor: pointer;
  background: url(../../assets/activity_images/pc/gift.png) no-repeat;
}

.gift-fixed .box-popover {
  background: url(../../assets/activity_images/pc/popover.png) no-repeat;
  width: 120px;
  height: 180px;
  position: absolute;
  bottom: 110px;
  left: 50%;
  margin-left: -60px;
}

.box-popover {
  font-size: 12px;
  text-align: center;
  line-height: 1.2;
}

.box-popover .inner {
  padding: 15px 0;
}

.box-popover .txt1 {
  color: #fa6400;
  margin-top: 5px;
}

.box-popover .txt2 {
  color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 1600px) {
  .gift-fixed {
    left: 50%;
    margin-left: 650px;
  }
}

::v-deep .custom-dialog {
  width: 580px;
  text-align: center;
  border-radius: 10px;
  background: url(../../assets/activity_images/m/pop.png) no-repeat bottom
    center;
  background-size: cover;
}

::v-deep .custom-dialog .el-dialog__header {
  height: 0;
  padding: 0;
}

.dialog-main {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 20px;
}

.dialog-main .d-flex {
  margin-bottom: 40px;
}

.dialog-main .d-title {
  width: 150px;
  height: 26px;
  padding-left: 15px;
  padding-right: 10px;
  line-height: 26px;
  color: #ffffff;
  background: url(../../assets/activity_images/m/ttlg.png) no-repeat;
  background-size: 100% 100%;
  font-size: 16px;
}

.dialog-main .d-text {
  font-size: 20px;
  color: #ff5247;
  padding: 0 15px;
}

.dialog-main .d-text .lg {
  font-size: 24px;
}

.dialog-main .d-wx img {
  width: 140px;
}

.dialog-main .d-sm {
  font-size: 14px;
  color: #ff5247;
}

.d-flex-code {
  margin-bottom: 30px;
}

.d-flex-code input {
  font: 400 16px Arial;
  border-color: #f4f4f4;
  background-color: #ebebeb;
  border-style: groove;
  margin-right: 10px;
}

.d-flex-code .txt {
  color: #ff5247;
  font-size: 14px;
  padding-right: 12px;
}

.d-flex-code .btn {
  width: 78px;
  height: 32px;
  border-radius: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: #ff5247;
  font-size: 14px;
}

.d-flex-code .txt-input {
  background-color: #f4f4f4;
  text-align: center;
  width: 190px;
  height: 32px;
  line-height: 32px;
  color: #666666;
  font-size: 16px;
  margin: 0 10px;
}

.d-flex-code .text {
  color: #ff5247;
  font-size: 14px;
}

.dialog-main .zt-list {
  margin-bottom: 40px;
  text-align: left;
}

.dialog-main .zt-list li {
  font-size: 16px;
  white-space: nowrap;
  color: #333333;
  margin-bottom: 26px;
}

.dialog-main .zt-list li img {
  width: 20px;
}

.dialog-main .d-button {
  width: 160px;
  height: 30px;
  background-color: #ff5247;
  border-radius: 40px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
}

.dialog-main .zt-list li .bg-sm {
  color: #ff5247;
}

::v-deep .el-dialog__headerbtn {
  color: #ffffff;
  border: 2px solid #fffffe;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  right: auto;
  left: 50%;
  top: auto;
  bottom: -70px;
  margin-left: -15px;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #ffffff !important;
  font-size: 20px;
}

.table-in {
  width: 400px;
}

.table-in .el-table thead td {
  color: rgba(0, 0, 0, 0.5);
  line-height: 14px;
}

.table-in .el-table tbody td {
  color: #ff5247;
  line-height: 14px;
}

.table-in .el-table tbody td img {
  width: 28px;
}

.table-in .el-table td {
  text-align: center;
  border-bottom: 0;
  padding: 6px 0;
}

.table-in .el-table::before {
  display: none;
}
</style>
