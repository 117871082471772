import { render, staticRenderFns } from "./activity.vue?vue&type=template&id=52abab6a&scoped=true"
import script from "./activity.vue?vue&type=script&lang=js"
export * from "./activity.vue?vue&type=script&lang=js"
import style0 from "./activity.vue?vue&type=style&index=0&id=52abab6a&prod&scoped=scoped&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52abab6a",
  null
  
)

export default component.exports